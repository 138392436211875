import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApiBoardsService} from 'src/app/modules/task-manager/services/api-boards.service';
import {ConfigBoardsService} from 'src/app/modules/task-manager/services/config-boards.service';
import {FileType, getFileExtensionWidget,} from '../../../../../models/file';
import {LinkEditFormComponent} from '../link-edit-form/link-edit-form.component';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';

@Component({
    selector: 'app-fileupload-edit-form',
    templateUrl: './fileupload-edit-form.component.html',
    styleUrls: ['./fileupload-edit-form.component.scss'],
})
export class FileuploadEditFormComponent implements OnInit {
    @Output() public readonly onClose: EventEmitter<boolean> = new EventEmitter();
    @Output() public readonly onFilesChange: EventEmitter<any> =
        new EventEmitter();
    @Output() public readonly onLinkAdd: EventEmitter<any> = new EventEmitter();
    public formGroup: UntypedFormGroup;
    public deletedFile = [];
    public dialogForm = null;
    public isDeleteConfirm = false;
    public deleteIndex = null;
    private readonly GOOGLE_DOC_LINK = 'https://docs.google.com/document/d/';
    private readonly GOOGLE_PR_LINK = 'https://docs.google.com/presentation/d/';
    private readonly GOOGLE_SS_LINK = 'https://docs.google.com/spreadsheets/d/';
    // Триггер смерти компонента
    private destroyed = new Subject<void>();

    constructor(
        private api: ApiBoardsService,
        private config: ConfigBoardsService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data,
        private notiService: GlobalNotificationCenterService
    ) {
    }

    get docCount() {
        return this.data.files.filter((el) => this.isFile(el)).length;
    }

    get imageCount() {
        return this.data.files.filter((el) => this.isImage(el)).length;
    }

    ngOnInit() {
        this.formGroup = new UntypedFormGroup({});
        this.setControlsValue();
    }

    onFocusChange(index, item) {
        const dot = this.data.files[index].fileName.lastIndexOf('.');
        const type = this.data.files[index].fileName.slice(dot, this.data.files[index].fileName.length);

        if (this.formGroup.controls[item.id].value && this.formGroup.controls[item.id]) {
            const newDot = this.formGroup.controls[item.id].value.lastIndexOf('.');
            let newVal = '';
            if (newDot >= 0) {
                newVal = this.formGroup.controls[item.id].value.slice(0, newDot) + type;
            } else {
                newVal = this.formGroup.controls[item.id].value + type;
            }
            this.formGroup.controls[item.id].setValue(newVal);
            this.data.files[index].fileName = newVal;
        }
    }

    setControlsValue() {
        this.data.files.forEach((el) => {
            this.formGroup.addControl(
                el.id.toString(),
                new UntypedFormControl(el.fileName, [Validators.required])
            );
        });
    }

    deleteFile(index) {
        this.formGroup.removeControl(this.data.files[index].id.toString());
        this.deletedFile.push(this.data.files[index].id.toString());
        this.data.files.splice(index, 1);
    }

    deleteLink(index) {
        this.data.links.splice(index, 1);
    }

    isFile(item) {
        return item.type === FileType.FILE;
    }

    isImage(item) {
        return item.type === FileType.IMAGE;
    }

    getImageUrl(file) {
        return `${this.config.fileStoragePicsUrl}${file.name}/large`;
    }

    getFileUrl(item) {
        return (
            '/assets/file_extension/' + getFileExtensionWidget(item.name) + '.png'
        );
    }

    public close() {
        this.dialogRef.close();
    }

    public getLink(link: string) {
        if (link.includes(this.GOOGLE_DOC_LINK)) {
            return 'radius_google_doc';
        } else if (link.includes(this.GOOGLE_PR_LINK)) {
            return 'radius_google_presentation';
        } else if (link.includes(this.GOOGLE_SS_LINK)) {
            return 'radius_google_spreadsheet';
        } else {
            return 'radius_link';
        }
    }

    public save() {
        const controls = this.formGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.notiService.handleError('Нельзя сохранять файлы без названия');
                return;
            }
        }

        let counter = 0;

        if (this.deletedFile.length > 0) {
            for (let i = 0; i < this.deletedFile.length; i++) {

                if (this.deletedFile[i] > 0) {
                    this.api.deleteFile('boards', this.deletedFile[i])
                        .pipe(takeUntil(this.destroyed))
                        .subscribe((res) => {
                                counter++;
                                if (counter === this.deletedFile.length) {
                                    this.closeModal();
                                }
                            }, (err) => {
                                this.notiService.handleFullError(err);
                                counter++;
                                if (counter === this.deletedFile.length) {
                                    this.closeModal();
                                }
                            }
                        );
                } else {
                    counter++;
                    if (counter === this.deletedFile.length) {
                        this.closeModal();
                    }
                }

            }
        } else {
            this.closeModal();
        }
    }

    public closeModal() {
        this.onLinkAdd.emit(this.data.links);
        this.onFilesChange.emit(this.data.files);
        this.dialogRef.close();
    }

    i;
    openEditForm(evt, index) {
        evt.stopPropagation();

        this.dialogForm = this.dialog.open(LinkEditFormComponent, {
            width: '500px',
            height: '316px',
            data: {
                name: this.data.links[index].name,
                url: this.data.links[index].url,
            },
        });

        this.dialogForm.afterClosed().subscribe((result) => {
            if (result) {
                this.data.links[index].name = result.name;
                this.data.links[index].url = result.url;
            }
        });
    }
}
