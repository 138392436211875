<label class="label">
  <span *ngIf="label" class="txt">{{ label }}</span>
  <input *ngIf="!textarea"
         [type]="type"
         [placeholder]="placeholder"
         [value]="value"
         formControlName="formControlName"
         class="input">
  <textarea *ngIf="textarea"
            [type]="type"
            [placeholder]="placeholder"
            [value]="value"
            formControlName="formControlName"
            class="textarea"
            autocomplete="off"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="0"
            cdkAutosizeMaxRows="100"></textarea>
  <span *ngIf="error" class="error txt">{{ error }}</span>
</label>
