import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bap',
    templateUrl: './progress-bap.component.html',
    styleUrls: ['./progress-bap.component.scss'],
})
export class ProgressBapComponent {
    @Input() public value: number;

    getColor(): string {
        if (this.value >= 0.1 && this.value <= 35) {
            return '_blue';
        } else if (this.value <= 70) {
            return '_orange';
        } else if (this.value < 99.9) {
            return '_green';
        } else if (this.value >= 99.9) {
            return '_dark-green';
        } else {
            return '';
        }
    }
}
