<div class="blur-box">
  <div class="abp">
    <h2 class="title">
      У вас обнаружно расширение блокирующее всплывающие окна или рекламу.<br>
      Пожалуйста, отключите его или добавьте наш сайт в исключния.<br>
      В противном случае часть функций портала будет не доступна.
    </h2>

    <button mat-flat-button class="button _blue" (click)="onClose()">Ок</button>
  </div>
</div>
