<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <form class="form kit" [formGroup]="formGroup">
        <label class="label kit _assessment">
                    <span class="label__txt">
                        Название должности
                    </span>
            <input class="input"
                      formControlName="title"
                      autocomplete="off">
        </label>

    </form>


    <footer class="dialog__footer">
        <app-button class="button" type="reset" buttonType="outlined" title="Отмена" (click)="close()"></app-button>
        <app-button class="button" type="submit" [title]="title" (click)="onSubmit()"></app-button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>
