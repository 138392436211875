<header class="header">
    <nav class="nav-tabs">
        <h2 class="nav-tabs__heading">{{ title }}</h2>
        <ul class="nav-tabs__list" *ngIf="applications && applications.length">

            <ng-container *ngFor="let item of applications">
                <li class="nav-tabs__item">
                    <a class="link" routerLinkActive="_active" [routerLink]="'/' + configPath + item.url"
                       [ngClass]="{'_active': item.name === appName}"
                    >
                        {{ item.viewName }}

                        <div class="fancy-bottom-border"></div>
                    </a>
                </li>
            </ng-container>
        </ul>
    </nav>

    <ng-content></ng-content>

    <app-compnay-menu-avatar [companyId]="companyId" *ngIf="companyId"></app-compnay-menu-avatar>
</header>
