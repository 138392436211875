/*********** Функции для преобразования типов из 1с *********/
import * as moment from 'moment/moment';

import {FormControl} from '@angular/forms';

import {environment} from '../../environments/environment';
import {Account} from '../models/account';

export function Boolean1c(value): boolean {
    if (value === 'true' || value === true) {
        return true;
    } else {
        return false;
    }
}

export function dateFormat(date) {
    return moment(date).format('DD.MM.YYYY');
}

export function dateFormatShort(date) {
    const momentObj = moment(date);

    if (!date || !momentObj.isValid()) {
        return '-';
    }

    const day = momentObj.format('DD');
    const month = momentObj.get('month');

    return `${day} ${MONTHS_SHORT[month]}`;
}

export function float1c(value): number {
    if (!value) {
        return 0;
    } else {
        return parseFloat(value.toString());
    }
}

export function int1c(value): number {
    if (!value) {
        return 0;
    } else {
        return parseInt(value.toString());
    }
}

/***************** функции связанные с датами*******************/

/**
 * Валидная ли дата
 *
 * @params date
 */
export function isValid(date: Date) {
    return date && date instanceof Date && !isNaN(date.getTime());
}

export function dateIsInRightFormat(date) {
    const reg = new RegExp(
        '([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))',
    );
    return (
        date &&
        date instanceof Date &&
        !isNaN(date.getTime()) &&
        date.toISOString().match(reg)
    );
}

/**
 * Возвращает истину, если это один день(без учета времени)
 *
 * @params date1
 * @params date2
 */
export function areOneDay(date1: Date, date2: Date): boolean {
    if (!isValid(date1) || !isValid(date2)) {
        return false;
    }
    return (
        new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
        ).getTime() ===
        new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
        ).getTime()
    );
}

/********************** Прочие функции ****************************/
export function hashCode(str: string) {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) {
        return hash;
    }
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
/**
 * Консоль для дева
 *
 * @params params {any}
 */
export function debug(...params: any) {
    if (!environment.production) {
        console.log(...params);
    }
    return false;
}
/**
 * Добавляет к числу лидирующие нули
 */
export function zerofill(number, length = 5) {
    if (!number) {
        return '';
    }
    let output = number.toString();
    while (output.length < length) {
        output = '0' + output;
    }
    return output;
}

/**
 * Убирает у номера лидирующие нули
 */
export function numberWithoutZerros(str: string) {
    if (str) {
        return str.replace(/^0+/, '');
    } else {
        return '';
    }
}

/************** ПОИСКИ **************************************/
/**
 * Обеспечивает работу поиска в селектах (кроме контрагентов)
 *
 * @param control - контрол с полем поиска
 * @param baseList - базовый (изначальный) список объектов
 * @param positionFlag - для поиска должностей с другой моделью
 * Возвращает отфильтрованный список объектов
 */
export function filterValues<T>(
    searchTerm: string,
    baseList: T[],
    positionFlag?: boolean,
): T[] {
    let newList;

    if (searchTerm) {
        const terms_str = searchTerm
            .toLowerCase()
            .split(' ')
            .map((i) => i.trim())
            .filter((i) => i);
        newList = baseList.filter((item) =>
            terms_str.every((term) => testItem<T>(item, term, positionFlag)),
        );
    } else {
        newList = baseList;
    }
    return newList;
}

/**
 * Проверяет на соответствие условию поиска по полю name
 *
 * @param item - объект для тестирования
 * @param term - строка поиска
 */
export function testItem<T>(item: T, term: string, positionFlag?: boolean) {
    if (positionFlag) {
        return item && testString(item['title'], term);
    }
    return item && testString(item['name'], term);
}

export function testString(value: string, term: string) {
    if (!!value) {
        return value.toString().toLowerCase().includes(term);
    }
    return false;
}

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomBoolean() {
    return Math.random() < 0.5;
}

export function getStartOfDay(day) {
    day.setHours(0);
    day.setMinutes(0);
    day.setSeconds(0);
    return day;
}

export function getEndOfDay(day) {
    day.setHours(23);
    day.setMinutes(59);
    day.setSeconds(59);
    return day;
}

export function getMonday(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
}

export function getEndOfQuarter(day) {
    const quarter = Math.floor((day.getMonth() + 3) / 3);

    if (quarter === 4) {
        return getEndOfDay(new Date(day.getFullYear() + 1, 1, 1));
    } else {
        return getEndOfDay(new Date(day.getFullYear(), quarter * 3, 0));
    }
}

export function getStartOfQuarter(day) {
    const quarter = Math.floor((day.getMonth() + 3) / 3);

    return new Date(day.getFullYear(), quarter * 3 - 3, 1);
}

export function getStartOfYear(day) {
    return new Date(day.getFullYear(), 0, 1);
}

export function getEndOfYear(day) {
    return getEndOfDay(new Date(day.getFullYear(), 11, 31));
}

export function debounce(delay: number = 300): MethodDecorator {
    return function (
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor,
    ) {
        const timeoutKey = Symbol();

        const original = descriptor.value;

        descriptor.value = function (...args) {
            clearTimeout(this[timeoutKey]);
            this[timeoutKey] = setTimeout(
                () => original.apply(this, args),
                delay,
            );
        };

        return descriptor;
    };
}

// usages
// declOfNum(count, ['стул', 'стула', 'стульев']);
export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
    ];
}

// отображение параметров с "+" в URL
export function getParameterURLByName(name, url = window.location.search) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results || !results[2] || results[2] === '') {
        return null;
    }
    return results[2].replace(/%20/g, '+');
}

// сортировка почты
export function sortEmails(accounts: Account[]) {
    accounts.sort((a, b) => {
        if (a.isVerified) {
            return -1;
        }
        if (b.isVerified) {
            return 1;
        }
    });
    accounts.sort((a, b) => {
        if (a.isNotifiable) {
            return -1;
        }
        if (b.isNotifiable) {
            return 1;
        }
    });
}

/**
 * Маска для календарей
 */
export function defaultDateMask() {
    const num = new RegExp('\\d');
    return {
        mask: [num, num, '.', num, num, '.', num, num, num, num],
        showMask: false,
        guide: false,
        placeholderChar: '_',
    };
}

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const DAYS_OF_WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
export const DAYS_OF_WEEK_UPPERCASE = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']

export const MONTHS_SHORT = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
];

export const MONTHS_SHORT_UPPERCASE = [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
];

export const isArray =
    Array.isArray || ((x) => x && typeof x.length === 'number');

export function isObject(x) {
    return x != null && typeof x === 'object';
}

export function urlify(text) {
    if (text && text.length) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + '</a>';
        });
    } else {
        return '';
    }
}

// TODO move somewhere
const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',

    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export function formatCurrency(sum: number) {
    if (sum === null) return '– ₽';
    if (sum < 0) return '0 ₽';

    return formatter.format(sum) || '0 ₽';
}

export function noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
}

export interface IHasId {
    id: any;
}

export const sortById = (a: IHasId, b: IHasId) => {
    if (a.id < b.id) {
        return -1;
    }
    if (a.id > b.id) {
        return 1;
    }
    return 0;
};

export const focusClearIfNull = (event) => {
    const target = event.target as HTMLInputElement;
    if (target.value == '0') {
        target.value = '';
    }

    return true
}

export const blurRestoreZero = (event) => {
    const target = event.target as HTMLInputElement;
    if (!target.value) {
        target.value = '0';
    }

    console.log(target.value);

    return true;
}
