import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild,} from '@angular/core';
import {ConfigService} from '../../../../../services/config.service';
import {ChatMessage} from '../../../../../models/chat';
import {ApiService} from '../../../../../services/api.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NavService} from '../../../../task-manager/services/nav.service';
import {ChatsService} from '../../../../../services/chats.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {PhotoGalleryService} from '../../../../../services/photo-gallery.service';
import {FileType, getFileExtensionWidget} from '../../../../../models/file';
import {saveAs as importedSaveAs} from 'file-saver';
import {NotificationCenterService} from '../../../../notification-center/services/notification-center.service';
import {TasksService} from '../../../../task-manager/services/tasks.service';

@Component({
    selector: 'app-chat-msg',
    templateUrl: './chat-msg.component.html',
    styleUrls: ['./chat-msg.component.scss']
})
export class ChatMsgComponent implements OnInit, OnDestroy {


    yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
    today = (d => new Date(d.setDate(d.getDate())))(new Date());
    /** редактированть сообщение */
    @Output() editChatMsg: EventEmitter<ChatMessage> = new EventEmitter();
    @Output() deleteChatMsg: EventEmitter<ChatMessage> = new EventEmitter();
    @Output() cancelChatMsg: EventEmitter<ChatMessage> = new EventEmitter();
    @Output() addImageToTask: EventEmitter<ChatMessage> = new EventEmitter();
    @Output() showReplyMessage: EventEmitter<ChatMessage> = new EventEmitter();
    /** Сообщение чата */
    @Input() chatMsg: ChatMessage;
    @Input() isTaskEntity = null;
    isAttachmentLoader: boolean = false;
    uploadingImgUrl = null;
    uploadingFile = null;
    @Input() msgWithAttach: boolean = false;
    /** Id текущего пользователя */
    @Input() currentUserId: number;
    /** Происходит ли редактирование */
    @Input() isEdit: boolean;
    public userOpened = false;
    public task = null;
    private destroyed = new Subject<void>();
    public tagUserId = null;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    public fileIdToDownload = [];
    public audio = null;
    public noErrors = true;

    readonly PDF_MIME = 'application/pdf';
    readonly PDF_FORMAT = '.pdf';
    public loaderPercent = 94;

    constructor(
        public config: ConfigService,
        private api: ApiService,
        private nav: NavService,
        private renderer: Renderer2,
        private chatService: ChatsService,
        private galleryService: PhotoGalleryService,
        private tasksService: TasksService,
        public notiService: NotificationCenterService,
    ) {
    }

    get isDownLoading() {
        return !this.uploadingFile && this.chatMsg &&
            this.chatMsg.attachments[0] &&
            this.fileIdToDownload.length &&
            this.fileIdToDownload.some((el) => el == this.chatMsg.attachments[0].id)
    }

    get canAddImage() {
        if (!this.isTaskEntity) {
            return false;
        }

        const task = this.tasksService.openedTask$.value;
        const allreadyAdded = task.files.some(el => el.id === this.chatMsg.attachments[0].id);

        return !allreadyAdded;
    }

    onReplyClick(msg) {
        this.chatService.replyMsg$.next(msg)
    }

    addToTask() {
        this.addImageToTask.emit(this.chatMsg);
    }

    ngOnInit(): void {
        if (this.chatMsg.links && this.chatMsg.links.length && this.chatMsg.links[0].link_type == 'task' && !this.task) {
            this.getTask();
        }

        this.chatService.downloadingFiles$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.fileIdToDownload = res
            });

        this.chatService.attachmentsDraft$
            .pipe(takeUntil(this.destroyed))
            .subscribe((file) => {
                if (file && this.chatMsg.fileUploading) {
                    this.isAttachmentLoader = true;
                    this.uploadingFile = file;
                    if (this.isImage()) {
                        const reader = new FileReader();

                        reader.onloadend = (url) => {
                            this.uploadingImgUrl = url.target.result;
                        };

                        reader.readAsDataURL(file);
                    }
                }
            });

        this.chatService.loaderPercent$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    this.loaderPercent = 100 - res - 6;

                    if (+this.loaderPercent < 0) {
                        this.loaderPercent = 0;
                    }
                } else {
                    this.loaderPercent = 94;
                }
            })

        // this.saveAudio();
    }

    resetUpload() {
        this.chatService.resetUpload$.next(true);
        this.chatService.attachmentsDraft$.next(null);
        this.deleteMessage();
    }

    hasTag(evt) {
        return evt.search('@') >= 0;
    }

    onClickLink(evt, tag) {
        this.tagUserId = null;

        if (evt.srcElement.className.search('_tag') >= 0) {
            const link = evt.srcElement.innerText;
            const users = this.chatService.members$.value;

            const user = users.find(user => {
                if (user.link.toString().toLocaleLowerCase() == link.toString().toLocaleLowerCase()) {
                    return user;
                }
            });

            setTimeout(() => {
                if (user) {
                    this.tagUserId = user.id
                } else {
                    this.tagUserId = undefined;
                }
            })
        } else {
            this.menuTrigger.closeMenu();

        }
    }

    getTask() {
        this.api.getShortTaskById(this.chatMsg.links[0].entity_id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.task = res.data;
                this.noErrors = true;
            }, err => {
                this.task = null;
                this.noErrors = false;
            });
    }

    get hasTask() {
        return this.chatMsg.links && this.chatMsg.links[0] && this.noErrors;
    }

    openTask(task) {
        if (this.chatService.opened$.value) {
            this.chatService.taskToOpen$.next(task);
        } else {
            this.nav.openTaskByVirtualId(task.vid);
        }
    }

    get hasAttachment() {
        return this.chatMsg.attachments && this.chatMsg.attachments[0] || this.uploadingFile;
    }

    /** Редактировать сообщение */
    changeMessage() {
        this.editChatMsg.emit(this.chatMsg);
    }

    /** Удалить сообщение */
    deleteMessage() {
        this.deleteChatMsg.emit(this.chatMsg);
    }


    /** Удалить сообщение */
    cancelMessage() {
        this.deleteChatMsg.emit(this.chatMsg);
    }

    /** Прошло ли 24 часа с момента отправки сообщения */
    isPassedDay() {
        return Math.floor((new Date().getTime() - this.chatMsg.createdAt.getTime()) / 3600 / 1000) > 24;
    }

    isMsgToday() {

            return (this.chatMsg.createdAt.getFullYear() === this.today.getFullYear())
                && (this.chatMsg.createdAt.getMonth() === this.today.getMonth())
                && (this.chatMsg.createdAt.getDate() === this.today.getDate());

    }

    isMsgYesterday() {

            return ((this.yesterday.getFullYear() === this.chatMsg.createdAt.getFullYear())
                && (this.yesterday.getMonth() === this.chatMsg.createdAt.getMonth())
                && (this.yesterday.getDate() === this.chatMsg.createdAt.getDate()));

    }

    getImageUrl(file) {
        if (this.isAttachmentLoader) {
           return this.uploadingImgUrl;
        } else {
            return `${this.config.fileStoragePicsUrl}${file.name}/large`;
        }
    }

    getFileUrlPdf() {
        const file = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];
        if (this.isPdf()) {
            return `${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`;
        }

        return '';
    }

    getAudioUrl() {
        const file = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];

        return `${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`

    }

    saveAudio() {
        const file = this.chatMsg.attachments[0];

        this.api.getFile(file.entityId, file.id.toString(), file.name)
            .subscribe(res => {

                const blob = new Blob([res], {type: file.mime});
                let blobUrl = URL.createObjectURL(blob);
                this.audio = blobUrl;

                // window.open(`${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`, '_blank');

            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    isPdf() {
        const file = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];

        return file.mime === this.PDF_MIME && file.fileName.search(this.PDF_FORMAT) > 0;
    }

    getFileUrl(item) {
        return '/assets/file_extension/' + getFileExtensionWidget(item.name) + '.png';
    }

    isFile() {
        const item = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];

        if (item.mime?.search('tiff') >= 0) {
            return true;
        }

        return item.type === FileType.FILE;

    }


    isImage() {
        const item = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];
        if (this.isAttachmentLoader && item.type.includes('image') && !item.type.includes('tiff')) {
            return true;
        }

        if (!this.isAttachmentLoader && item.mime?.includes('image') && !item.mime?.includes('tiff')) {
            return true;
        }
        return item.type === FileType.IMAGE;
    }

    isAudio() {
        const item = this.isAttachmentLoader ? this.uploadingFile : this.chatMsg.attachments[0];
        if (item && item.mime && item.mime.includes('audio')) {
            return true;
        }

        return false;
    }

    onReplyMsgClick() {
        this.showReplyMessage.emit(this.chatMsg.reply_to)
    }

    downloadFile(file) {
        if (this.isDownLoading) {
            return false;
        }

        this.notiService.handleMessage('Загрузка файла');
        const files = [...this.chatService.downloadingFiles$.value, file.id];
        this.chatService.downloadingFiles$.next(files);
        this.api.getFile(file.entityId, file.id, file.name)
            .subscribe(res => {
                const blob = new Blob([res], {type: file.mime});
                    importedSaveAs(blob, file.fileName);
                    this.clearDownloadFileId(file.id);
                }, err => {
                    this.notiService.handleFullError(err);
                    this.clearDownloadFileId(file.id);
                });
    }

    clearDownloadFileId(id) {
        let files = this.chatService.downloadingFiles$.value;
        files = files.filter(el => el !== id);
        this.chatService.downloadingFiles$.next(files);
    }

    openImage(image, event: PointerEvent) {
       if (this.isAttachmentLoader) {
           //  this.cancelMessage();
        }  else {
            this.galleryService.photos$.next([image]);
            this.galleryService.currentPhotoId$.next(image.id);
            this.galleryService.entityId$.next(image.entityId);
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
        this.chatService.downloadingFiles$.next([]);
    }

}
