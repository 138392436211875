import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Application} from '../../../phonebook/models/application';
import {Subject} from 'rxjs';
import {NavService} from '../../../phonebook/services/nav.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit, OnDestroy, OnChanges {

    @Input() applications: Application[] = [];
    @Input() configPath = null;
    @Input() appName = null;
    @Input() title = null;
    private destroyed = new Subject<void>();
    public companyId = null;

    constructor(
        private nav: NavService,
        private auth: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.companyId = this.auth.currentUser$.getValue().company?.id;

    }

    redirectTo(path) {
        this.nav.open(this.configPath + path);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.applications.currentValue) {
            this.applications = changes.applications.currentValue;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
