import {Component, Inject, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../../../services/global-notification-center.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PhonebookService} from 'src/app/modules/phonebook/services/phonebook.service';
import {ConfigPhonebookService} from 'src/app/modules/phonebook/services/config-phonebook.service';
import {ApiCompanyService} from 'src/app/modules/phonebook/services/api-company.service';
import {PositionShort} from '../../../../../phonebook/models/position-short';

@Component({
  selector: 'app-position-add',
  templateUrl: './position-add.component.html',
  styleUrls: ['./position-add.component.scss']
})
export class PositionAddComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;
    public subdivisionId = null;
    public isLoaded = false;
    private positions: PositionShort[] = [];
    private destroyed = new Subject<void>();
    readonly ERROR_MESSAGE = 'Введите название должности';

    constructor(
        private notiService: GlobalNotificationCenterService,
        private apiCompany: ApiCompanyService,
        private phonebook: PhonebookService,
        private config: ConfigPhonebookService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    public get title() {
        return 'Добавить должность';
    }

    ngOnInit(): void {

        this.positions = this.phonebook.positions$.value;

        this.formGroup = new UntypedFormGroup({
            title: new UntypedFormControl(this.data ? this.data.title : '', [Validators.required]),
        });
        this.isLoaded = true;

    }


    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    onSubmit() {
        if (this.formGroup.invalid) {
            this.notiService.handleError(this.ERROR_MESSAGE);
            return;
        }
        const title = this.formGroup.controls['title'].value;
        if (this.positions.find(el => el.title.toUpperCase() === title.toUpperCase())) {
            this.notiService.handleError(this.config.POSITION_NAME_INVALID);
            return false;
        }

        const formData = {
            title,
            users: []
        };

        this.isLoaded = false;

        this.apiCompany.createPosition(formData)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.phonebook.positionCreated$.next(res);
                this.close();
            }, (err) => {
                this.notiService.handleFullError(err);
                this.isLoaded = true;
            });

        }

        close() {
            this.dialogRef.close();
        }
    }
