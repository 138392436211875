<section class="form" [formGroup]="formGroup">
    <h3 class="title">Вложения</h3>
    <div class="file-container">

        <div class="files" *ngIf="data.files && docCount">
            <h5 class="files__title">Документы ({{docCount}})</h5>
            <ng-container *ngFor="let item of data.files; let index = index">
                <li class="files__item" *ngIf="isFile(item)">
                    <img class="file__img"
                        [src]="getFileUrl(item)">
                    <div class="txt-container">
                        <label class="label kit">
                            <input
                                tabindex="-1"
                                class="input txt__item"
                                formControlName={{item.id}}
                                (blur)="onFocusChange(index, item)"
                                />
                        </label>
                    </div>
                    <div> </div>
                    <div class="buttons-container">
                        <!-- другой клик при другой модалке -->
                        <!-- (click)="deleteQuestion(index)"> -->
                        <button mat-icon-button class="button-form"
                        (click)="deleteFile(index)">
                        <mat-icon class="icon _delete">close</mat-icon>
                    </button>
                    </div>
                </li>
            </ng-container>
        </div>

        <ul class="files" *ngIf="data.links && data.links.length">
            <h5 class="files__title">Ссылки ({{data.links.length}})</h5>
            <ng-container *ngFor="let link of data.links, let index = index">
                <li class="files__item">
                    <mat-icon *ngIf="getLink(link.url) === 'radius_link'" class="icon _link _common-link" svgIcon='radius_link'></mat-icon>
                    <mat-icon *ngIf="getLink(link.url) !== 'radius_link'" class="icon _link" svgIcon={{getLink(link.url)}}></mat-icon>
                    <a class="link" (click)="openLink($event, link)" [href]="link['url'] | safeUrl" target="_blank" [matTooltip]="link['name'] ? link['name'] : link['url']">
                        {{ link['name'] ? link['name'] : link['url'] }}
                    </a>
                    <div></div>
                    <div class="buttons-container">
                        <button mat-icon-button class="button-form"
                            (click)="openEditForm($event, index)">
                            <mat-icon class="icon _delete" svgIcon="radius_edit"></mat-icon>
                        </button>
                        <button mat-icon-button class="button-form"
                            (click)="deleteLink(index)">
                            <mat-icon class="icon _delete">close</mat-icon>
                        </button>
                    </div>
                </li>
            </ng-container>
            <div class="separator"></div>
        </ul>

        <div class="files" *ngIf="data.files && imageCount">
            <h5 class="files__title">Файлы ({{imageCount}})</h5>
            <ng-container *ngFor="let item of data.files; let index = index">
                <div class="images__item" *ngIf="isImage(item)">
                    <img class="images__img"
                         [src]="getImageUrl(item)" >
                    <div class="txt-container _image">
                        <label class="label kit">
                            <input
                                tabindex="-1"
                                class="input txt__item"
                                formControlName={{item.id}}
                                (blur)="onFocusChange(index, item)"
                                />
                        </label>
                    </div>
                    <button mat-icon-button class="button-form"
                        (click)="deleteFile(index)">
                        <mat-icon class="icon _delete">close</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="footer">
        <button mat-flat-button class="button _white _cancel"
            (click)="close()">Отменить
        </button>
        <button mat-flat-button class="button _blue _save"
            (click)="save()">Сохранить
        </button>
    </div>
</section>

<ng-container *ngIf="isDeleteConfirm">
    <app-confirm title="Вы уверены, что хотите удалить документ?"
                 confirmTitle="Удалить"
                 cancelTitle="Отменить"
                 (confirm)="isDeleteConfirm = false; deleteFile(this.deleteIndex)"
                 (cancel)="isDeleteConfirm = false">
    </app-confirm>
</ng-container>