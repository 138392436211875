<section>
    <header class="dialog__header">
        <h2 class="dialog__title">
            {{ data.title }}
        </h2>
        <mat-icon aria-label="close" class="close" (click)="close()">close</mat-icon>
    </header>

    <div class="dialog__content">
        <section class="users _empty" *ngIf="users.length">
            <h2 class="title" *ngIf="data.showUsersCount">
                {{ data.usersCountText }} <span class="total">
                ({{ users.length  }})</span>
            </h2>

            <section class="section">
                <div class="users__content">
                    <ul class="users__list _inner max-height">
                        <li class="users__list-item" *ngFor="let item of users">
                            <app-user-list-item [fullName]="false" [userSubdivision]="item"></app-user-list-item>

                            <mat-checkbox class="checkbox-18px" [(ngModel)]="item.checked"></mat-checkbox>
                        </li>
                    </ul>
                </div>
            </section>
        </section>
    </div>

    <footer class="dialog__footer">
        <app-button [title]="data.cancelBtnText" [tabIndex]="1" (click)="close()" [buttonType]="'outlined'"></app-button>
        <app-button [title]="data.okBtnText" [tabIndex]="0" (click)="save()" [buttonType]="'field'" focus="true"
                    [disabled]="isDisabled()"></app-button>
    </footer>
</section>
