import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiCompanyService} from '../../../services/api-company.service';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Subject} from 'rxjs';
import {PositionsService} from '../../../services/positions.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PositionFolder} from '../../../models/position-folder';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-position-short-form',
    templateUrl: './position-short-form.component.html',
    styleUrls: ['./position-short-form.component.scss']
})
export class PositionShortFormComponent implements OnInit, OnDestroy {
    public isLoaded = false;
    public formGroup: UntypedFormGroup = null;
    private destroyed = new Subject<void>();
    public title = 'Создать должность';
    public groups: PositionFolder[] = [];
    public isSubmitClicked = false;

    constructor(
        private api: ApiCompanyService,
        private positions: PositionsService,
        public dialogRef: MatDialogRef<any>,
        private notiService: GlobalNotificationCenterService,
    ) {
    }

    ngOnInit(): void {
        this.groups = this.positions.groups$.value;

        this.formGroup = new UntypedFormGroup({
            title: new UntypedFormControl({
                value: '',
                disabled: false
            }, [Validators.required]),
            group_id: new UntypedFormControl({
                value: -1,
                disabled: false
            }),
        });
    }


    close() {
        this.dialogRef.close(1);
    }

    hasError(elem) {
        return (this.isSubmitClicked && elem.invalid) || (elem.invalid && (elem.dirty || elem.touched));
    }


    onSubmit() {
        const val = this.formGroup.controls['title'].value.trim();
        this.formGroup.controls['title'].setValue(val);
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return false;
        }

        const data = this.formGroup.getRawValue();
        if (data.group_id === '') {
            data.group_id = null;
        }

        this.api.createPosition(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.positions.positionCreated$.next(res);
                this.close();
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
