import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {
  @Input() public textarea = false;
  @Input() public type = 'text';
  @Input() public error = '';
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public value = '';
  @Input() public formControlName = '';

  constructor() { }

  ngOnInit() {
  }

}
