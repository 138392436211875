import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {statusMapping, StatusType} from '../../../../../models/status';
import {defaultDateMask} from '../../../../../utils/commons';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {MatDatepickerInput} from '@angular/material/datepicker';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {ApiPhonebookService} from '../../../../phonebook/services/api-phonebook.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss']
})
export class StatusSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showLabels = true;
  @Input() userInfo = null;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  private destroyed = new Subject<void>();

  public statuses = statusMapping.map(el => el.type);
  /** конфиг для маски дат */
  public maskConfig = defaultDateMask();
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    status: new UntypedFormControl( 0),
    statusStart: new UntypedFormControl(null),
    statusEnd: new UntypedFormControl( null),
  });
  @ViewChild('statusRangePicker') statusRangePicker: MatDatepickerInput<any>;

  constructor(
      private notiService: GlobalNotificationCenterService,
      private api: ApiPhonebookService,
  ) {

  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngOnInit() {
    // clear if no end date
    if (!this.formGroup.controls['statusEnd'].value || !this.userInfo.checkStatusDay()) {
      this.formGroup.controls['statusStart'].setValue(null);
      this.formGroup.controls['statusEnd'].setValue(null);
    }

    // submit on status change
    this.formGroup.controls['status'].valueChanges
        .pipe(debounceTime(400))
        .subscribe(val => {
          this.submitStatus();
        });
  }

  statusName(statusType) {
    const status = statusMapping.find(el => el.type === statusType);
    return status ? status.name : '';
  }

  get statusPeriodAvailable() {
    return this.formGroup.controls['status'].value && this.formGroup.controls['status'].value !== StatusType.BIRTHDAY;
  }

  isStatusExpired() {
    if (this.userInfo?.status) {
      const statusEnd = this.userInfo?.status.dateEnd ? moment(this.userInfo?.status.dateEnd) : null;
      if (statusEnd) {
        return statusEnd.isAfter(moment().subtract(1, 'days'));
      }
    }
    return false;
  }

  submitStatus() {
    if (!this.userInfo) {
 return;
}

    let newStatus = this.formGroup.controls['status'].value;
    if (newStatus === 0) {
 newStatus = null;
}

    const newFrom = this.formGroup.controls['statusStart'].value;
    const newTo =  this.formGroup.controls['statusEnd'].value;

    const st = (type, from, to) => ( {
      type,
      date_start: from ?
          moment(from).utc(true) : null,
      date_end: from && to ?
          moment(to).utc(true) : null
    });

    const status = newStatus ? st(newStatus, newFrom, newTo) : null;

    const oldStatus = st(this.userInfo.status?.type, this.userInfo.status?.dateStart, this.userInfo.status?.dateEnd);

    // do not update if status is same
    if (status === oldStatus) {
 return;
}

    const formData = {
      ...this.userInfo,
      status,
    };

    this.api.editUser(formData, this.userInfo.id)
        .pipe(takeUntil(this.destroyed))
        .subscribe(res => {
          this.userInfo = res['user'];
          this.onUpdate.emit(this.userInfo);
        }, (err) => {
          this.notiService.handleFullError(err);
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userChanges = changes['userInfo'];

    if (userChanges.firstChange && userChanges.previousValue !== userChanges.currentValue) {
      // userInfo had changed
      this.userInfo = userChanges.currentValue;
      this.updateForm();
    }
  }

  private updateForm() {
    //const isExpired = this.isStatusExpired();
    this.formGroup.controls['status'].setValue(
        this.userInfo?.status ?
            this.statuses.find(el => el === this.userInfo?.status?.type) : 0
    );
    this.formGroup.controls['statusStart'].setValue(
        this.userInfo?.status?.dateStart,
    );
    this.formGroup.controls['statusEnd'].setValue(
        this.userInfo?.status?.dateEnd,
    );
  }

  clearStartDate() {
    this.userInfo.status.dateStart = null;
    this.userInfo.status.dateEnd = null;
    this.updateForm();
  }

    onCalendarClose() {
      // submit on calendar close
      this.submitStatus();
    }
}
