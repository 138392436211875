<mat-card class="task _create"
          [ngStyle]="{'padding-left': view && view === 'gantt' ? paddingLeft ? paddingLeft + 'px' : '75px': null}"
          (click)="openShortForm()"
          [ngClass]="{'_hide': isShortFormOpened}">

    <mat-icon class="status">add</mat-icon>

    <div class="task__title">
        <span>{{ placeholder() }}</span>
    </div>

</mat-card>

<mat-card class="task _form _opened"
          [ngClass]="{'_hide': !isShortFormOpened}"
          [ngStyle]="{'padding-left': view && view === 'gantt' ? paddingLeft ? paddingLeft + 'px' : '75px': null}"
>

    <form class="form" [formGroup]="formGroupShort" (ngSubmit)="onSubmit()">
        <textarea #taskTitle
                  class="input"
                  formControlName="title"
                  autocomplete="off"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="0"
                  cdkAutosizeMaxRows="100"
                  placeholder="Введите название"
                  (blur)="onFocusChange()"
                  (keypress)="onKeyPress($event)">
        </textarea>
        <div class="toolbar">
            <button mat-icon-button type="button" *ngIf="formGroupShort.controls['title'].value"
                    class="button submit"
                    (click)="onSubmit()">
                <mat-icon>done</mat-icon>
            </button>
            <button mat-icon-button type="button" class="button close" (click)="closeShortForm()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </form>

</mat-card>
