import {Component, Input, OnInit} from '@angular/core';
import {MobilePhone} from '../../../../../models/user';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {
  @Input() phone: MobilePhone;
  isSended = false;

  constructor() { }

  ngOnInit(): void {
  }

  sendCipher() {
    this.isSended = true;
  }
}
