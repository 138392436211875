import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {takeUntil} from 'rxjs/operators';
import {JSON} from 'ta-json';
import History from '../../models/task-history';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

    public listData: any;
    @Input() taskId: string;
    @Input() type: string;
    private destroyed = new Subject<void>();

    constructor(
        public api: ApiService,
        private notiService: GlobalNotificationCenterService
    ) {
    }

    ngOnInit() {
        this.api.getHistoryById(this.taskId, this.type)
            .pipe(takeUntil(this.destroyed))
            .subscribe(params => {
                this.listData = JSON.deserialize<History>(params, History);
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
