const apiBase = 'https://api.development.myradius.ru/';
const wsBase = 'wss://ws.development.myradius.ru/';

const push = apiBase + 'platform-push-messaging/api/v1/';
const auth = apiBase + 'platform-authorization/api/v1/';
const companies = apiBase + 'platform-companies/api/v1/';
const authLogin = auth + 'login/';
const authorization = apiBase + 'platform-authorization/api/v1/authorization/';
const apps = apiBase + 'platform-applications/api/v1/applications/';
const users = apiBase + 'platform-authorization/api/v1/users/';
const rating = apiBase + 'platform-new-learning/api/rating/';
const subdivisions = apiBase + 'platform-authorization/api/v1/subdivisions/';
const invites = apiBase + 'platform-authorization/api/v1/invites/';
const sendEmail = apiBase + 'platform-mailer/api/v1/simple/';
const archiveUsers = apiBase + 'platform-authorization/api/v1/archive/';
const platformLearning = apiBase + 'platform-new-learning/api/';
const fileStorage = apiBase + 'files-storage/';
const notificationSettings =
    apiBase + 'platform-notifications-new/api/v1/settings/';
const transfertBack = apiBase + 'transfert-back/';
/** Смарт-контракты (включая подписание) */
const smartContracts = apiBase + 'platform-smart-contract/';
/** Заявки */
const paymentRequest = apiBase + 'payment_request/';
/** Права */
const permissions = apiBase + 'payment_request/permissions/';
const allCirclesRootId = 'c8856816-0263-11e8-80c0-005056bd352a';
const newsRequest = apiBase + 'platform-company-news/api/v1/';
const newsRequestV2 = apiBase + 'platform-company-news/api/v2/';
const bugTracker = apiBase + 'platform-bug-tracker/';
const taskManager = apiBase + 'boards/';
const learning = apiBase + 'platform-learning/api/';
/** файлы базы знаний */
const knowledgeBaseFiles = apiBase + 'gfiles/';
const motivations = apiBase + 'platform-motivation/api/v1/';
const matrix = apiBase + 'platform-matrix/api/v1/';
const functions = apiBase + 'functional_structure/function/';
const functionsQuestion = apiBase + 'functional_structure/question/';
const functionsSettings = apiBase + 'functional_structure/settings/';
const companySettings = apiBase + 'functional_structure/settings/';
const questions = apiBase + 'functional_structure/survey/';
const chatApi = apiBase + 'platform-chats/api/v1/';
const importerUrl = apiBase + 'platform-importers/api/v1/';

export const environment = {
    production: false,
    apiBase,
    apps,
    authApiUrl: apiBase + 'platform-authorization/api/v1/authorization/',
    authorization,
    checkEmail: invites + 'check_account/',
    authorizationInvites: invites,
    authorizationInvitesComplete:
        auth + 'authorization/sign-up-invite-complete/',
    sendEmail: sendEmail,
    deliveryServiceBase: apiBase + 'delivery',
    fileStorageURL: fileStorage,
    fileStoragePicsUrl: fileStorage + 'pics/files/',
    fileStorageUsersUrl: fileStorage + 'files/user/',
    fileStorageTasksUrl: fileStorage + 'files/boards/',
    circlesApi: transfertBack + 'trees/org_units',
    photoAvatarUser: fileStorage + 'pics/user/',
    userPhotoUUIDURL: fileStorage + 'pics/user_uuid',
    logoOrgUnitURL: fileStorage + 'pics/group',
    dadataToken: '080f4617a3adce038e10aeb3ad21af8ef81f387d',
    firebase: {
        apiKey: 'AIzaSyC6k2JCW5gXtTmSDOJsbv-PvBLmQCjV6uU',
        authDomain: 'radius-f65b4.firebaseapp.com',
        databaseURL: 'https://radius-f65b4.firebaseio.com',
        projectId: 'radius-f65b4',
        storageBucket: 'radius-f65b4.appspot.com',
        messagingSenderId: '343770206311',
        appId: '1:343770206311:web:5a6217db5b5d14f7',
    },
    fcm: push,
    wsChatApiPath: wsBase + 'realtime',
    chatRooms: chatApi + 'chat_rooms',
    chatRoomUsers: chatApi + 'chat_room_users',
    chatRoomNotification: chatApi + 'chat_room_notification',
    chatHistory: chatApi + 'chat_history',
    sendMessage: chatApi + 'entity/',
    editChatMessage: chatApi + 'message',
    notificationsHistory:
        apiBase + 'platform-notifications-new/api/v1/history/',
    setNotyficationSettings: notificationSettings,
    getNotyficationSettings: notificationSettings,
    getBoardsCounters: taskManager + 'counter/important_all',
    usersCirclesWithAdmin: transfertBack + 'members/circles/',
    myCircles: apiBase + 'payment_request/circle/by_owner/myself',
    unitSearch: transfertBack + 'trees/org_units_search/',
    permissions: apiBase + 'payment_request/permissions/by_user/',
    smartContracts,
    paymentRequest: paymentRequest + 'payment_request',
    permissionsBase: permissions,
    newsRequests: newsRequest + 'news/',
    newsRequestsV2: newsRequestV2 + 'news/',
    chatMembers: chatApi + 'user_list',
    addChatMember: chatApi + 'chat_rooms/add_user',
    removeChatMember: chatApi + 'chat_rooms/delete_user',
    chatAvailableMembers: chatApi + 'user_list_available',
    allCirclesRootId,
    allCircles:
        transfertBack +
        'trees/org_units_list/' +
        allCirclesRootId +
        '?direction=down',
    taskManagerUrl: taskManager,
    taskManagerTaskUrl: taskManager + 'task',
    taskManagerBoardUrl: taskManager + 'board',
    taskManagerTagUrl: taskManager + 'tag',
    fileStorage,
    history: apiBase + 'history',
    userPhotoURL: fileStorage + 'pics/user',
    platformCalendar: apiBase + 'platform-calendar/',
    importerUrl,

    usersUrl: users,
    ratingUrl: rating,
    archiveUsers,
    shortUsersCalendar: users + 'short/',

    circlesTree: paymentRequest + 'tree_circles',

    questionUrl: learning + 'question/',
    questionsUrl: learning + 'question/by-themes/',
    userQuestionsUrl: learning + 'answers/',
    themesUrl: learning + 'theme/',
    statisticUrl: learning + 'statistics',
    permissionsUrl: learning + 'permissions/',
    userStatisticUrl: learning + 'users_statistics',
    settingsUrl: learning + 'settings/',
    settingsFrequencyURL: learning + 'frequency_options/',

    phonebookSubdivisions: subdivisions,
    structAdmin: apiBase + 'struct/adm/',
    structAdminLite: apiBase + 'struct/adm/?template=lite',
    structPositions: auth + 'positions/',
    structPosition: apiBase + 'struct/position/',
    structSlot: apiBase + 'struct/slot/',
    structSort: apiBase + 'struct/sort/',
    positionStructFolder: apiBase + 'struct/folder/',
    positionGroups: auth + 'position_groups/',

    accessRights: apiBase + 'permits/',

    setNotifiableEmail: authLogin + 'set_notifiable_email/',

    authLoginEmail: authLogin,
    authEmailInvite: authLogin + 'invite/',
    authEmailAcceptInvite: authLogin + 'accept_invite/',

    companyProfile: companies + 'company_profile/',

    subdivisionUrl: bugTracker + 'subdivisions',
    taskUrl: bugTracker + 'tasks/',
    circlesUrl: bugTracker + 'subdivisions/',

    knowledgeBaseFiles,

    motivations,
    motivationsSettings: motivations + 'settings',
    motivationsCustomFields: motivations + 'custom_fields',

    functions,
    functionsQuestion,
    functionsSettings,
    companySettings,

    matrixBaseUrl: matrix,
    matrixAccess: matrix + 'access',
    matrix: matrix + 'matrix',
    matrixSetting: matrix + 'settings',
    matrixSubdivision: matrix + 'subdivision',
    deputy: matrix + 'deputy',
    matrixSmartTasks: matrix + 'smart_task',
    questions,

    userHelpers: users + 'helps_modules',

    paymentsService: apiBase + 'platform-payments',
    tariffsService: apiBase + 'platform-tariffs',
    billingService: apiBase + 'platform-billing',
    isPublicBeta: false,
    functionalStructure: apiBase + 'functional_structure',
    /** Академия */
    platformLearning: platformLearning,
    topics: platformLearning + 'topics/',
    settingAcademy: platformLearning + 'setting/',
    tests: platformLearning + 'tests/',
    questionsAcademy: platformLearning + 'questions/',
    assignments: platformLearning + 'assignments/',
    answers: platformLearning + 'answers/',
    questionHub: platformLearning + 'questionHub/',
    userAcademy: platformLearning + 'users/info',
    statistics: platformLearning + 'statistics/',
    reassignDepartments:
        platformLearning + 'tests/departments/reassign-departments',
};
