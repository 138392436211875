<div *ngIf="!phone.isVerified">
    <div *ngIf="!isSended">
        <a class="link _send" (click)="sendCipher()">Подтвердить</a>
    </div>
    <div *ngIf="isSended">
        <div class="description">
            На Ваш номер +7-ХХХ-ХХХ-ХХ-ХХ отправлена СМС.
            Введите код из СМС для подтверждения.
        </div>
        <div class="cipher-input-block">
            <input maxlength="4" class="input">
            <button class="btn">Подтвердить</button>
        </div>
    </div>
</div>

<div *ngIf="phone.isVerified">
    Verified
</div>
