import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-horizontal-spinner',
  templateUrl: './horizontal-spinner.component.html',
  styleUrls: ['./horizontal-spinner.component.scss']
})
export class HorizontalSpinnerComponent {

  @Input() height = 100;
}
