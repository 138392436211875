import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {MotivationsPeriodCustomField} from './motivations-period-custom-field';
import {MotivationsUserShort} from './motivations-user-short';
import {User} from '../../../../models/user';
import {File} from '../../../../models/file';
import {Link} from '../../../../models/link';
import {Matrix} from '../matrix/matrix';
import moment from 'moment';

export enum PeriodicityEnum {
    MONTHLY = 'MONTHLY',
    ONCE_IN_TWO_WEEKS = 'ONCE_IN_TWO_WEEKS'
}

export const PeriodicityTypeMapping = Object.freeze([
    {
        value: PeriodicityEnum.MONTHLY,
        name: 'Ежемесячно'
    },
    {
        value: PeriodicityEnum.ONCE_IN_TWO_WEEKS,
        name: 'Раз в две недели'
    }
]);


export class MotivationsUserCoord {
    @JsonProperty('approve')
    @JsonType(Boolean)
    approve;

    @JsonProperty('string')
    @JsonType(String)
    coordinator_type;

    @JsonProperty('read')
    @JsonType(Boolean)
    read;

    @JsonProperty('role')
    @JsonType(String)
    role;

    @JsonProperty('user')
    @JsonType(MotivationsUserShort)
    user: MotivationsUserShort;
}

export class Motivation {
    @JsonProperty('current')
    @JsonType(Boolean)
    current?: boolean;

    @JsonProperty('coordinators')
    @JsonType(MotivationsUserCoord)
    coordinators?: MotivationsUserCoord[] = [];

    @JsonProperty('motivations_custom_fields')
    @JsonType(MotivationsPeriodCustomField)
    motivationsCustomFields: MotivationsPeriodCustomField[] = [];

    @JsonProperty('motivation_type')
    @JsonType(String)
    type: String;

    @JsonProperty('date_finish')
    @JsonType(Date)
    dateFinish: Date;

    @JsonProperty('date_start')
    @JsonType(Date)
    dateStart: Date;

    @JsonProperty('is_probation')
    @JsonType(Boolean)
    isProbation = false;

    @JsonProperty('is_approved')
    @JsonType(Boolean)
    isApproved = false;

    @JsonProperty('note')
    @JsonType(String)
    note: string;

    @JsonProperty('periodicity')
    @JsonType(String)
    periodicity: string;

    @JsonProperty('matrix')
    @JsonElementType(Matrix)
    matrix?: Matrix[];

    @JsonProperty('salary')
    @JsonType(Number)
    salary: number;

    @JsonProperty('has_matrix')
    @JsonType(Boolean)
    hasMatrix: boolean;

    @JsonProperty('variable_path')
    @JsonType(Number)
    variablePath: number;

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('creator')
    @JsonType(User)
    creator: User;

    @JsonProperty('accrual_type')
    @JsonType(String)
    accrualType?: string = null;

    @JsonProperty('status')
    @JsonType(String)
    status?: string;

    @JsonProperty('hours')
    @JsonType(Number)
    hoursPerPeriod?: number;

    @JsonProperty('files')
    @JsonElementType(File)
    files: File[] = [];

    @JsonProperty('links')
    @JsonElementType(Link)
    links: Link[] = [];

    @JsonProperty('user')
    @JsonElementType(User)
    user: User;


    @JsonProperty('user_motivation_id')
    @JsonElementType(Number)
    empId: number;

    @JsonProperty('motivation_id')
    @JsonElementType(Number)
    motId: number;

    @JsonProperty('user_motivation_id')
    @JsonType(Number)
    user_motivation_id?: number;

    unread_mesages?: number;
    unreadMessages?: number;

    public static getMotivationDateRangeStr(motivation: Motivation) {
        let result = '';

        if (!motivation.dateFinish) {
            result += 'с ';
        }
        const dtFormat = 'DD.MM.yyyy';
        result += moment(motivation.dateStart).format(dtFormat);

        if (motivation.dateFinish) {
            result += ' – ' + moment(motivation.dateFinish).format(dtFormat);
        }

        return result;
    }

    constructor(
        id?: number,
        additionalCoordinators: MotivationsUserShort[] = [],
        permanentCoordinators: MotivationsUserShort[] = [],
        dateStart: Date = null,
        dateFinish: Date = null,
        isProbation: boolean = false,
        note: string = '',
        periodicity: string = PeriodicityEnum.MONTHLY,
        salary: number = null,
        variablePath: number = null
    ) {
        this.id = id;
        this.dateStart = dateStart;
        this.dateFinish = dateFinish;
        this.isProbation = isProbation;
        this.note = note;
        this.periodicity = periodicity;
        this.salary = salary;
        this.variablePath = variablePath;

    }

    public static getMotivationType(motivation: Motivation) {
        if (motivation.type === 'CONSTANT') {
            if (motivation.isProbation) {
                return 'Испытательный срок';
            } else {
                return 'Постоянная';
            }
        }
        if (motivation.type === 'BONUS') {
            return 'Бонусная';
        }

        return '-';
    }

}
