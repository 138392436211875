<article *ngIf="!motivation" class="sidebar-card kit center">
    <mat-spinner></mat-spinner>
</article>

<article *ngIf="motivation" [ngClass]="{'_chatrooms': chatRoomsFlag, '_edit': !readonly, '_short': showEmpBox, '_chat': isChatOpen}"
         class="sidebar-card kit _no-radius">
    <header [ngClass]="'_' + getMotivationStatusClass(motivation.status)" class="sidebar-card__header">
        <div class="card__title">Мотивация</div>
        <div *ngIf="readonly" [ngClass]=" '_' + getMotivationStatusClass(motivation.status) " class="card__motiv-status"
        >{{ getMotivationStatus(motivation.status) }}
        </div>
        <div class="spacer"></div>
        <div *ngIf="!readonly" class="card__motiv-status card__motiv-status--edit"
        >Редактирование
        </div>

        <button (click)="toggleChat()"
                *ngIf="!isNew && !chatRoomsFlag && readonly"
                [matTooltip]="hasUnreadMessages ? 'Есть непрочитанные сообщения' : 'Открыть чат'"
                [ngClass]="{'_unread': hasUnreadMessages, '_chat-open': isChatOpen }"
                class="button _chat _icon"
                type="button"
        >
            <mat-icon svgIcon="radius_chat"></mat-icon>
        </button>

        <div class="header_buttons">
            <button (click)="allowEdit()" *ngIf="readonly && !onlyReadonly && !isChatOpen && !chatRoomsFlag && !isNew && canChangeMotivation() && canEdit()" class="icon-btn button _icon">
                <mat-icon class="icon-btn__close">edit</mat-icon>
            </button>

            <button (click)="closeMot()" *ngIf="!chatRoomsFlag" class="icon-btn button _icon">
                <mat-icon class="icon-btn__close">close</mat-icon>
            </button>

            <ng-container *ngIf="chatRoomsFlag">
                <button (click)="goToApp()" *ngIf="hasAccess && !itsMe()" class="_back">
                    <mat-icon [svgIcon]="'radius_calendar_small'" class="icon"></mat-icon>
                    Открыть в мотивациях
                </button>
                <button (click)="goToCabinet()" *ngIf="itsMe()" class="_back">
                    <mat-icon [svgIcon]="'radius_calendar_small'" class="icon"></mat-icon>
                    Открыть в ЛК
                </button>
            </ng-container>
        </div>
    </header>

    <div *ngIf="readonly && !isChatOpen"
         class="motivation__mini-card-wrap">
        <div class="motivation__mini-card-bg" [ngClass]=" '_' + getMotivationStatusClass(motivation.status)"></div>
        <div class="motivation__mini-card mini-card">
            <div class="mini-card__title">
                {{ getMotivationType(motivation) }}
            </div>
            <div class="mini-card__date">
                <mat-icon class="icon calendar-icon" svgIcon="radius_calendar"></mat-icon>
                {{ getMotivationDateRangeStr(motivation) }}
            </div>
            <div class="minicard__salary">
                <div class="minicard__salary-total">{{ total | number }} ₽</div>
                <div class="minicard__salary-accural-type">{{ getPeriodicity(motivation) }}</div>
            </div>
        </div>
    </div>

    <div *ngIf="isChatOpen || chatId !== null" class="chat sidebar-card__content">
        <app-motivations-chat (close)="chatClose()"
                              [date]="getDate()"
                              [isPrivate]="withoutUser"
                              [multiple]="!itsMe() && !chatRoomsFlag"
                              [title]="userName"
                              [usersMotivationId]="chatId || motivation.empId"
                              class="chat"></app-motivations-chat>
    </div>

    <ng-container *ngIf="!isChatOpen">

        <overlay-scrollbars
                [options]="{ scrollbars: { autoHide: 'scroll' } }"
                class="sidebar-card__content _no"
                [defer]>
            <div class="employment-box">
                <app-user-list-item [userSubdivision]="this.user"></app-user-list-item>
                <div class="grey-box">
                    <div class="header" (click)="onHeaderClick()">
                        <div class="header__txt">
                            Информация о трудоустройстве
                        </div>
                        <div class="buttons">
                            <!--                <button type="button" *ngIf="!isOnlyOne && !isCompact"-->
                            <!--                        class="button _chat _icon"-->
                            <!--                        [matTooltip]="'Удалить трудоустройство'"-->
                            <!--                        (click)="deleteEmp($event)"-->
                            <!--                >-->
                            <!--                    <mat-icon>delete_outline</mat-icon>-->
                            <!--                </button>-->
                            <div class="header__btn button _icon">
                                <mat-icon *ngIf="!isExpanded" class="header__btn-icon _big">expand_more</mat-icon>
                                <mat-icon *ngIf="isExpanded" class="header__btn-icon _big">expand_less</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="form" *ngIf="isExpanded">
                        <div class="employment__separator"></div>

                        <label>
                            <span class="label__txt">Дата трудоустройства</span>
                            <div *ngIf="readonly">{{ formatDate(emp.employmentDate, 'dd.MM.yy', 'ru') }}</div>
                        </label>
                        <label>
                            <span class="label__txt">Трудоустройство</span>
                            <div *ngIf="readonly">{{ getEmploymentType(emp) }}</div>
                        </label>
                        <label>
                            <span class="label__txt">Юридическое лицо</span>
                            <div *ngIf="readonly">{{ emp.company?.name || 'Не указана' }}</div>
                        </label>
                        <label *ngIf="emp.rate">
                            <span class="label__txt">Ставка</span>
                            <div *ngIf="readonly">{{ emp.rate }}</div>
                        </label>
                    </div>
                </div>
                <button class="blue-button" (click)="openFull()" *ngIf="isAdmin">
                    <mat-icon class="icon">arrow_upward</mat-icon>
                    <span class="txt">Смотреть всю информацию</span>
                </button>
            </div>

            <form *ngIf="motivationFormGroup" [formGroup]="motivationFormGroup" [ngClass]="{'_highlight-erors': showErrors}"
                  class="_scroll">

                <section class="sidebar-card__section">
                    <label class="label kit">
                        <span class="label__txt">Вид мотивации:</span>
                        <mat-select *ngIf="!readonly" formControlName="motivation_type">
                            <mat-option [value]="MotivationType.Constant">Постоянная</mat-option>
                            <mat-option [value]="MotivationType.Bonus">Бонусная</mat-option>
                        </mat-select>

                        <div *ngIf="readonly">{{ getMotivationType(motivation) }}</div>
                    </label>

                    <label class="label kit _date">
                        <span class="label__txt">Период:</span>
                        <div *ngIf="!readonly" [ngClass]="{'invalid': startInput.value.length === 0}"
                             class="date-selector _editable">
                                    <span class="input _range">
                                    </span>

                            <mat-date-range-input [rangePicker]="motivationRangePicker" class="range-picker _editable"
                            >
                                <input #startInput [dateMask]="maskConfig" [placeholder]="'Выбрать период'" autocomplete="off"
                                       class="date" formControlName="date_start" matStartDate>
                                <input [dateMask]="maskConfig" [ngClass]="{'_hidden': !motivationFormGroup.controls['date_start'].value}" autocomplete="off" formControlName="date_finish"
                                       [min]="getMotivationDateStart()"
                                       matEndDate>
                            </mat-date-range-input>

                            <mat-date-range-picker  #motivationRangePicker></mat-date-range-picker>
                            <mat-datepicker-toggle [for]="motivationRangePicker" class="datepicker-toggler"
                                                   matSuffix></mat-datepicker-toggle>

                            <app-button (action)="clearDate()"
                                        *ngIf="startInput.value"
                                        buttonType="close"
                                        class="close-button" type="button"
                            ></app-button>
                        </div>
                        <mat-error *ngIf="showErrors && !motivationFormGroup.controls['date_start'].value">
                            Поле обязательно к заполнению
                        </mat-error>

                        <div *ngIf="readonly">
                            {{ motivation.dateStart | date: 'dd.MM.yyyy' }} –
                            <ng-container *ngIf="!isEditableDateFieldShown()">{{ motivation.dateFinish| date: 'dd.MM.yyyy' }}</ng-container>
                            <div class="input-date-wrap" *ngIf="isEditableDateFieldShown()">
                                <input [dateMask]="maskConfig" autocomplete="off" formControlName="date_finish"
                                       matInput placeholder="Дата окончания" class="input inline-input"
                                       [matDatepicker]="picker"
                                       [min]="getMotivationDateStart()"
                                >
                                <mat-datepicker-toggle matIconSuffix [for]="picker" *ngIf="!motivationFormGroup?.controls.date_finish?.value">
                                    <mat-icon matDatepickerToggleIcon svgIcon="radius_calendar_toggle" class="button-icon"></mat-icon>
                                </mat-datepicker-toggle>

                                <ng-container *ngIf="!!motivationFormGroup?.controls.date_finish?.value">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker" class="suffix-button suffix-second datepicker-toggle-36">
                                        <mat-icon matDatepickerToggleIcon  class="button-icon">edit</mat-icon>
                                    </mat-datepicker-toggle>
                                    <button matIconSuffix class="suffix-button" (click)="clearEndDate($event)">
                                        <mat-icon class="button-icon">clear</mat-icon>
                                    </button>
                                </ng-container>

                                <mat-datepicker #picker></mat-datepicker>
                            </div>
                        </div>
                    </label>

                    <mat-checkbox *ngIf="!readonly" class="checkbox label checkbox-18px" formControlName="has_matrix">
                        <span class="checkbox__txt">Сформировать матрицу</span>
                    </mat-checkbox>

                    <ng-container *ngIf="motivationFormGroup.controls['motivation_type'].value === MotivationType.Constant">
                        <mat-checkbox *ngIf="!readonly" class="checkbox label checkbox-18px" formControlName="is_probation">
                            <span class="checkbox__txt">Испытательный срок</span>
                        </mat-checkbox>

                        <label class="label kit">
                            <span class="label__txt">Форма оплаты:</span>
                            <mat-select *ngIf="!readonly" formControlName="accrual_type">
                                <mat-option [value]="AccuralType.PerHour">Почасовая</mat-option>
                                <mat-option [value]="AccuralType.PerMonth">Ежемесячная</mat-option>
                            </mat-select>

                            <div *ngIf="readonly">{{ getAccuralType(motivation) }}</div>
                        </label>

                        <ng-container *ngIf="motivationFormGroup.controls['accrual_type'].value === AccuralType.PerHour">
                            <label class="label kit">
                                <span class="label__txt">Расчётное количество часов за период:</span>
                                <input
                                        #hoursPerPeriod
                                        (blur)="restoreUnchanged($event)"
                                        (focusin)="clearOnFocus($event)"
                                        *ngIf="!readonly"
                                        class="input"
                                        formControlName="hours"
                                        min="1"
                                        required
                                        type="number"
                                >
                                <mat-error *ngIf="showErrors && !motivationFormGroup.controls['hours'].value">Поле
                                    обязательно к заполнению
                                </mat-error>
                                <div *ngIf="readonly">{{ motivation.hoursPerPeriod | number }}</div>
                            </label>
                        </ng-container>
                    </ng-container>
                </section>
                <section class="sidebar-card__section">

                    <div class="hr"></div>

                <h2 class="subheader">Сумма &laquo;на руки&raquo;</h2>

                <ng-container *ngIf="motivationFormGroup.controls['motivation_type'].value === MotivationType.Constant
                            && motivationFormGroup.controls['accrual_type'].value !== AccuralType.PerHour
                        ">
                    <label *ngIf="!readonly || motivation.salary" class="label kit">
                        <span class="label__txt">Оклад:</span>
                        <input (blur)="restoreUnchanged($event)" (focusin)="clearOnFocus($event)" *ngIf="!readonly"
                               class="input"
                               formControlName="salary" type="number"/>
                        <mat-error
                                *ngIf="showErrors && (!motivationFormGroup.controls['salary'].value && !motivationFormGroup.controls['variable_path'].value)">
                            Мотивация не может быть без суммы
                        </mat-error>
                        <div *ngIf="readonly">{{ motivation.salary | number }} ₽</div>
                    </label>

                    <label *ngIf="!readonly || motivation.variablePath" class="label kit">
                        <span class="label__txt">Переменная часть:</span>
                        <input (blur)="restoreUnchanged($event)" (focusin)="clearOnFocus($event)" *ngIf="!readonly"
                               class="input" formControlName="variable_path" type="number"/>
                        <mat-error
                                *ngIf="showErrors && (!motivationFormGroup.controls['salary'].value && !motivationFormGroup.controls['variable_path'].value)">
                            Мотивация не может быть без суммы
                        </mat-error>
                        <div *ngIf="readonly">{{ motivation.variablePath | number }} ₽</div>
                    </label>


                    <ng-container
                            *ngIf="motivationFormGroup.controls['periodicity'].value !== MotivationPeriodicity.OnceInTwoWeeks">
                        <label class="label kit _inline">
                            <span class="label__txt">Итого в месяц: </span><span>{{ total | number }} ₽</span>
                        </label>
                    </ng-container>

                    <ng-container
                            *ngIf="motivationFormGroup.controls['periodicity'].value === MotivationPeriodicity.OnceInTwoWeeks">
                        <label class="label kit _inline">
                            <span class="label__txt">Итого за 2 недели: </span><span>{{ total | number }} ₽</span><br/>
                            <span class="label__txt">Всего в месяц: </span><span>{{ total * 2 | number }} ₽</span>
                        </label>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="motivationFormGroup.controls['accrual_type'].value === AccuralType.PerHour">
                    <table class="pay-table">
                        <tr>
                            <td *ngIf="!readonly || motivation.salary">
                                <label class="label kit">
                                    <span class="label__txt">Оклад в час</span>
                                    <input (blur)="restoreUnchanged($event)" (focusin)="clearOnFocus($event, 'salary')" *ngIf="!readonly" [min]="1"
                                           class="input" formControlName="salary" required
                                    />
                                    <mat-error
                                            *ngIf="showErrors && (!motivationFormGroup.controls['salary'].value && !motivationFormGroup.controls['variable_path'].value)">
                                        Мотивация не может быть без суммы
                                    </mat-error>
                                    <div *ngIf="readonly">{{ motivation.salary | number }} ₽</div>
                                </label>
                            </td>

                            <td *ngIf="!readonly || motivation.salary">
                                <label class="label kit">
                                    <span class="label__txt">Сумма за период</span>
                                    <span class="txt">{{ getSummSalary()| number }} ₽</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td *ngIf="!readonly || motivation.variablePath">
                                <label class="label kit">
                                    <span class="label__txt">Переменная часть в час</span>
                                    <input (blur)="restoreUnchanged($event)" (focusin)="clearOnFocus($event, 'variable_path')"
                                           *ngIf="!readonly" class="input"
                                           formControlName="variable_path"
                                    />
                                    <mat-error
                                            *ngIf="showErrors && (!motivationFormGroup.controls['salary'].value && !motivationFormGroup.controls['variable_path'].value)">
                                        Мотивация не может быть без суммы
                                    </mat-error>
                                    <div *ngIf="readonly">{{ motivation.variablePath | number }} ₽</div>
                                </label>
                            </td>

                            <td *ngIf="!readonly || motivation.variablePath">
                                <label class="label kit">
                                    <span class="label__txt">Сумма за период</span>
                                    <span class="txt">{{ getSummVariable() | number }} ₽</span>
                                </label>
                            </td>
                        </tr>
                    </table>

                    <label class="label kit">
                        <span class="label__txt">Итого за период: </span>
                        <div class="txt">
                            оплата в час - {{ totalPerHour | number }} ₽<br/>
                            сумма за период - {{ totalPerHourPeriod | number }} ₽
                        </div>
                    </label>
                </ng-container>

                <ng-container *ngIf="motivationFormGroup.controls['motivation_type'].value === MotivationType.Bonus">
                    <label class="label kit">
                        <span class="label__txt">Периодичность начисления:</span>
                        <mat-select *ngIf="!readonly" formControlName="periodicity">
                            <mat-option [value]="MotivationPeriodicity.OneTime">Разовая</mat-option>
                            <mat-option [value]="MotivationPeriodicity.Monthly">Раз в месяц</mat-option>
                            <mat-option [value]="MotivationPeriodicity.OnceInQuarter">Раз в квартал</mat-option>
                            <mat-option [value]="MotivationPeriodicity.OnceInHalfYear">Раз в полгода</mat-option>
                            <mat-option [value]="MotivationPeriodicity.OnceInYear">Раз в год</mat-option>
                        </mat-select>

                        <div *ngIf="readonly">{{ getPeriodicity(motivation) }}</div>
                    </label>

                    <div class="hr"></div>

                    <label class="label kit">
                        <span class="label__txt">Сумма &laquo;на руки&raquo;</span>
                        <input (blur)="restoreUnchanged($event)" (focusin)="clearOnFocus($event)" *ngIf="!readonly"
                               class="input" formControlName="variable_path" type="number"
                        />
                        <mat-error *ngIf="showErrors && (!motivationFormGroup.controls['variable_path'].value)">Мотивация не
                            может быть без суммы
                        </mat-error>
                        <div *ngIf="readonly">{{ getVariable() | number }} ₽</div>

                        <label class="label kit _inline">
                            <span class="label__txt">Итого: </span><span>{{ total | number }} ₽</span><br/>
                        </label>
                    </label>
                </ng-container>
                </section>

                <section class="sidebar-card__section" *ngIf="isMeCoordinator()">
                    <div class="hr"></div>
                    <app-fileupload (filesChange)="onFilesChange($event)"
                                    (linksChange)="onLinksChange($event)"
                                    (setCover)="null"
                                    [canSetCover]="false"
                                    [cover]="null"
                                    [disabled]="isDisabled"
                                    [files]="motivation.files || []"
                                    [links]="motivation.links || []"
                                    [taskId]="motivation.id"
                                    [uploadCondition]="!isDisabled"
                                    class="section _fileupload"
                                    entityId="motivations"
                                    [additionalText]="'(видно только согласователям)'"
                                    [showIcon]="false"
                                    [gDocOutlet]="chatRoomsFlag ? 'chat': undefined"
                    ></app-fileupload>
                </section>
                <section class="sidebar-card__section _last">
                    <div class="hr"></div>
                    <ng-container *ngFor="let item of customFields, let index = index">
                        <ng-container *ngIf="!readonly || getCustomFieldValue(item)">
                            <label *ngIf="item['init']" class="label kit">
                            <span class="label__txt">
                                {{ item.name }}
                            </span>
                                <input (blur)="restoreUnchanged($event)"
                                       (focusin)="clearOnFocus($event)"
                                       *ngIf="!readonly && item.type === 'INTEGER'"
                                       [formControlName]="'cf' + item.id"
                                       [type]="inputTypes[item.type]"
                                       class="input">

                                <textarea #autosize="cdkTextareaAutosize"
                                          *ngIf="!readonly && item.type !== 'INTEGER'"
                                          [formControlName]="'cf' + item.id"
                                          autocomplete="off"
                                          cdkTextareaAutosize
                                          class="input _note">
                                ></textarea>

                                <div *ngIf="readonly">{{ getCustomFieldValue(item) }}</div>
                            </label>
                        </ng-container>
                    </ng-container>

                    <label *ngIf="!readonly" class="label kit">
                        <span class="label__txt">Примечание:</span>
                        <textarea #autosize="cdkTextareaAutosize"
                                  autocomplete="off"
                                  cdkTextareaAutosize
                                  class="input _note"
                                  formControlName="note"></textarea>
                    </label>

                    <label *ngIf="readonly && isMeCoordinator() && motivation.note" class="label kit notes">
                        <span class="label__txt">Примечание:</span>
                        <div>{{motivation.note}}</div>
                    </label>

                    <app-users-list (usersChange)="onUsersChange($event)"
                                    [approvers]="true"
                                    [buttonTitle]="'Добавить сотрудника'"
                                    [disabled]="isDisabled || readonly"
                                    [employee]="getEmployee()"
                                    [title]="'Согласование'"
                                    [users]="userlist"
                                    class="users _width100"
                    ></app-users-list>

                    <div *ngIf="motivation.id && !readonly && canEdit() && !chatRoomsFlag && isDeletable()" class="buttons delete-button">
                        <button (click)="onDeleteClick()" class="button _delete _transparent _left"
                                mat-flat-button
                                type="button">
                            <mat-icon [svgIcon]="'radius_delete'"></mat-icon>
                            Удалить мотивацию
                        </button>
                    </div>
                </section>
            </form>

            <footer *ngIf="isFooterShown()"
                    class="footer">

                <ng-container *ngIf="!isMotivationChanged() && readonly">
                    <div *ngIf="canSendToApprove() && !onlyAuthorApprover" class="buttons">
                        <app-button (action)="toApprove()" *ngIf="canApprove()"
                                    title="Отправить на согласование"></app-button>
                    </div>

                    <div *ngIf="canSendToApprove() && onlyAuthorApprover" class="buttons">
                        <app-button (action)="toApproveOnlyMe()" *ngIf="canApprove()" class="_green"
                                    title="Согласовать мотивацию"></app-button>
                    </div>

                    <div *ngIf="canApproveOrCancel()" class="buttons">
                        <app-button (action)="approve()" *ngIf="!isApprovedByMe() && canApprove()" class="_green"
                                    title="Согласовать мотивацию"
                        ></app-button>
                        <app-button (action)="cancelApprove()" *ngIf="isApprovedByMe() && canApprove()" [buttonType]="'outlined'"
                                    title="Отменить согласование"
                        ></app-button>
                    </div>

                    <div *ngIf="canCancelMotivation()" class="buttons">
                        <app-button (action)="cancelMotivation()" [buttonType]="'outlined'" title="Отменить мотивацию"
                        ></app-button>
                    </div>

                    <div *ngIf="
                    (motivation.status === MotivationStatus.FAMILIARIZE)
                    && itsMe() && !isApprovedByMe()" class="buttons">
                        <button (click)="approve()" class="btn _green">
                            Ознакомлен(а)
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="(isDateFinihsChanged() && canChangeMotivation()) || (canChangeMotivation() && !this.readonly)">
                    <div class="buttons">
                        <app-button (action)="cancel()" [buttonType]="'outlined'" title="Отменить"></app-button>
                        <app-button
                                (action)="onSave()"
                                [disabled]="!allowAddMotivations || invalidFields()" title="Сохранить"></app-button>
                    </div>
                </ng-container>
            </footer>
        </overlay-scrollbars>
    </ng-container>
</article>

<!--<app-photo-gallery></app-photo-gallery>-->

<!--<div [ngClass]="{'_visible': gDocIsOpen}" class="gDocDialog">-->
<!--    <button (click)="ongDocClose()" class="button gDocDialog-close">-->
<!--        <mat-icon class="icon">close</mat-icon>-->
<!--    </button>-->
<!--    <iframe class="frame" src=""></iframe>-->
<!--</div>-->
