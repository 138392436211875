<mat-accordion class="users section">
    <mat-expansion-panel class="users-expansion-panel" [expanded]="isExpandedDefault" #usersPanel="matExpansionPanel">
        <mat-expansion-panel-header class="users__header"
                                    [collapsedHeight]="'24px'"
                                    [expandedHeight]="'24px'">
            <h2 class="title">
                {{ title }} <span class="total" *ngIf="users.length">
                ({{ users.length }})</span>
            </h2>
        </mat-expansion-panel-header>

        <section class="section">
            <div class="users__content">
                <ul class="users__list _inner">
                    <li class="users__list-item" *ngFor="let item of users"
                        [ngClass]="{'_opened': item.id === openedUserId}">
                        <app-user-list-item [userSubdivision]="item"
                                             [removable]="!disabled && !item['disabled']"
                                             [disabled]="disabled || item['disabled']"
                                             [position]="position"
                                             [approvers]=approvers
                                             [approved]="approvers && item.isApproved"
                                             (onRemove)="removeUser(item)"
                                             (onUser)="openUser($event)"></app-user-list-item>
                    </li>
                    <li class="member users__list-item" *ngIf="!disabled">
                        <button mat-icon-button class="button _add _list" type="button" (click)="openUsersForm($event)">
                            <mat-icon>person_add</mat-icon>
                            <span class="txt">{{ buttonTitle }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </section>

    </mat-expansion-panel>

    <section class="users__content _header" *ngIf="users.length"
             (click)="usersPanel.open()"
             [ngClass]="{'_hide': usersPanel.expanded}">
        <ul class="users__list _header">
            <ng-container *ngFor="let item of users; let i = index">
                <li class="users__list-item _short" *ngIf="i < 5">
                    <app-user-list-item [userSubdivision]="item"
                                         [approvers]=approvers
                                         [approved]="approvers && item.isApproved"
                                         [shortView]="true"></app-user-list-item>
                </li>
            </ng-container>
            <li class="users__list-item _short" *ngIf="users.length > 5">
                <span class="oversize">+{{users.length - 5}}</span>
            </li>
        </ul>
    </section>

</mat-accordion>
